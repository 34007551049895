<template>
    <div>
        <mainone :mainonebread="mainonebread"></mainone>
        <!--table表单-->
        <div>
            <!--主list列表-->
            <div style="margin-top: 10px; background-color: #ffffff; border-radius: 4px; line-height: 40px; padding: 0 20px">
                <div style="line-height: 60px">
                    <!--左侧名称-->
                    <div style="float: left; font-size: 14px">数据列表</div>
                    <!--右侧按钮-->
                    <div style="float: right">
                        <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
                        <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
                        <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
                        <!--<el-button ></el-button>-->
                        <!--导出按钮-点击导出数据-->
                        <!-- 临时任务：20230217：隐藏资金方导出&table列表隐藏安装时间 -->
                   <!--     <el-button type="warning" plain @click="exportDatas()">导出</el-button> -->
                    </div>
                </div>

                <div>
                    <!--table表单-->
                    <el-table
                        ref="multipleTable"
                        :row-class-name="tableRowClassName"
                        :data="mainthreetableData.slice((currentPage - lastpage) * PageSize, currentPage * PageSize)"
                        style="width: 100%"
                        min-height="900"
                        border
                        v-loading="loading"
                        element-loading-text="正在飞速加载中..."
                        @selection-change="handleSelectionChange"
                        @cell-click="handleOneData"
                    >
                        <!--多选框-用于用户选择性导出数据-->
                        <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
                        <el-table-column
                            v-for="(mytabletitle, i) in mainthreetabletitle"
                            :key="i"
                            :fixed="mytabletitle.fixedstatu"
                            :prop="mytabletitle.prop"
                            :label="mytabletitle.label"
                            :width="mytabletitle.width"
                        >
                            <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
                            <!--例如：status状态字段，判断最终的值是那种表现形式等-->
                            <template #default="scope" v-if="mytabletitle.prop === 'client' || mytabletitle.prop === 'client_type' || mytabletitle.prop === 'addtime'">
                                <!-- 设备编号 -->
                                <div v-if="mytabletitle.prop === 'client'">
                                    <div
                                        :class="this.mypowerlimits.jqkz == true ? 'mytabletext' : ''"
                                        @click="this.mypowerlimits.jqkz == true ? routeraddrClick('/sharedvccontrol', scope.row) : ''"
                                    >
                                        <span>{{ scope.row.client }}</span>
                                    </div>
                                </div>
                                <div v-if="mytabletitle.prop === 'client_type'">
                                    <!-- <span>{{ this.client_type_options[scope.row.client_type] }}</span> -->
                                    <span>共享打印机</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!--分页器-->
                    <div class="block" style="text-align: center; margin: 30px 0">
                        <el-pagination
                            background
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage"
                            :page-sizes="pageSizes"
                            :page-size="PageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalCount"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mainone from '@/components/Mainone'
import maintwo from '@/components/Maintwo'
import axios from 'axios'
import { defineComponent, ref } from 'vue'
import dist from '@/assets/js/dist.js'

export default defineComponent({
    name: 'vsharedvcexfactoryZijinfangols',
    data() {
        return {
            mainonebread: [
                {
                    name: '打印'
                },
                {
                    name: '共享打印'
                },
                {
                    name: '设备信息'
                }
            ], // 当前页-【面包屑导航】
         
            mainthreeisshowbut: false, // 数据列表中-【添加按钮】
            mainthreeisshowset: false, // 数据列表中-复选框
            mainthreeisshowope: false, // 数据列表中-操作-根据页面显示模块（整页）
            mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
            mainthreehandle: 150, // 数据列表中-操作-宽度
            mainthreetabletitle: [
                {
                    fixedstatu: 'left',
                    prop: 'client',
                    label: '设备编号',
                    width: '100'
                },
                {
                    fixedstatu: false,
                    prop: 'client_type',
                    label: '设备类型',
                    width: '160'
                },

                
                {
                    fixedstatu: false,
                    prop: 'address',
                    label: '详细地址',
                    width: ''
                }
 
            ], // 数据列表中-数据表头
            mainthreestatus: true, // 数据列表中-属于search按钮表示状态
            mainthreetableData: [], // 数据列表中-具体数据
            totalCount: 1, //数据列表中-数据总条数
            lastpage: 1, //数据列表中-数据总页数（默认以20条一页）

            //table表单相关数据
            seniorform: {},
            currentPage: 1,
            pageSizes: [20, 50, 100],
            PageSize: 20,
            multipleSelection: [],

            // 设备类型
            client_type_options: dist.dvc_info.client_type,

            mypowerlimits: JSON.parse(localStorage.getItem('powerlimits')), //获取权限
            mytoken: localStorage.getItem('token'), //获取存储的token
            pagesdatas: [], //存储最近一次的筛选条件，供分页使用

            loading: false
        }
    },
    methods: {
        //分页器中-当选择条数时触发该函数
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`)
            this.PageSize = val
            this.currentPage = 1

            this.pagesdatas = {
                b: 's',
                // name: this.pagesdatas.client,
                // putintimez: this.pagesdatas.putintimez,
                // client_type: this.pagesdatas.client_type,
                page: 1,
                page_size: val
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //分页器中-当选择分页时触发该函数
        handleCurrentChange(val) {
            // console.log(`当前页: 第${val}页`)
            this.currentPage = val

            this.pagesdatas = {
                b: 's',
                // client: this.pagesdatas.client,
                // client_type: this.pagesdatas.client_type,
                page: val,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //点击进行路由页面跳转以及携带参数
        routeraddrClick(routeraddr, rowdata) {
            if (rowdata) {
                // 携带参数跳转
                if (routeraddr == '/sharedvccontrol') {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { client: rowdata.client }
                        })
                        .catch((error) => error)
                } else {
                    this.$router
                        .push({
                            path: routeraddr,
                            query: { id: rowdata.id }
                        })
                        .catch((error) => error)
                }
            } else {
                //仅跳转
                this.$router.push(routeraddr).catch((error) => error)
            }
        },
        //导出数据
        exportDatas() {
            this.pagesdatas = {
                b: 's',
                // client: this.pagesdatas.client,
                // putintimez: this.pagesdatas.putintimez,
                client_type: this.pagesdatas.client_type,
                export: 10 // 临时任务：特殊处理-该页面给资金方-导出传10
            }
            this.postAxiosEvent(this.pagesdatas, 1)
        },

        //获取Container-Pagedata-Maintwo-搜索-返回的数据
        getSeachinfoEvent(data) {
            //判断-投放-时间段状态

            this.pagesdatas = {
                b: 's',
                // client: data.client,
                // client_type: data.client_type,
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        },
        //数据渲染
        myaxiosfunc(successdata) {
			var newdata = successdata.data
			
			if(successdata.putintime == 1){
				var mainthreetabletitle = this.mainthreetabletitle
				mainthreetabletitle.push({
				    fixedstatu: false,
				    prop: 'putintime',
				    label: '投放时间',
				    width: ''
				});
				this.mainthreetabletitle = mainthreetabletitle
				for (var i = 0; i < newdata.length; i++) {
				    //转换投放时间
				    if (newdata[i].putintime) {
				        if (newdata[i].putintime === 0 || newdata[i].putintime === null) {
				            newdata[i].putintime = '无投放时间'
				        } else {
				            newdata[i].putintime = this.$utils.formatDate(newdata[i].putintime).substr(0, 10)
				        }
				    } else {
				        newdata[i].putintime = '无投放时间'
				    }
				}
			}
            
			
            console.log('当前页面的data:' + JSON.stringify(newdata))
            this.mainthreetableData = newdata
            this.totalCount = successdata.total
            this.currentpage = successdata.current_page //当前页面页数
            this.lastpage = successdata.last_page
            this.loading = false
        },
        postAxiosEvent(data, index) {
            //加载loading
            this.loading = true //通过Ajax向后台获取数据
            //通过Ajax向后台获取数据
            axios
                .get('/manage/Equipment/leaveRecordols', {
                    headers: {
                        Auth: localStorage.getItem('token')
                    },
                    params: data
                })
                .then((response) => {
                    if (!index) {
                        this.myaxiosfunc(response.data.result)
                    } else {
                        this.loading = false
                    }
                })
                .catch(function (error) {
                    console.log(error)
                })
        }
    },
    created() {
        if (localStorage.getItem('token') === null || !localStorage.getItem('token')) {
            this.isshow = false
            this.$router.push('/').catch((error) => error)
        } else {
            this.pagesdatas = {
                b: 's',
                page: 1,
                page_size: this.PageSize
            }
            this.postAxiosEvent(this.pagesdatas)
        }
    },
    components: {
        mainone
    }
})
</script>
<style scoped>
.mytabletext {
    color: var(--el-color-primary);
    cursor: pointer;
}
</style>
